.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.0025em;
  margin-bottom: 4px;
  color: #7c878b;
}
.desk-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  margin-bottom: 4px;
  color: #7c878b;
}

.word-count {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.005em;
  color: #7c878b;
  margin-bottom: 4px;
}

.desk-word-count {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  letter-spacing: 0.0025em;
  color: #7c878b;
  margin-bottom: 4px;
}

.textarea-style {
  height: 104px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.001em;
  padding: 12px;
}

.textarea-style::placeholder {
  color: #b8bdbd;
}

.desk-textarea-style {
  height: 104px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  padding: 12px;
}

.desk-textarea-style::placeholder {
  color: #b8bdbd;
}
.input-style-disable{
  background: #eeeeee !important;
}