@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.boxRadius8 {
  border-radius: 8px;
}
.boxRadius4 {
  border-radius: 4px;
}
.boxRadius6 {
  border-radius: 6px;
}

.borderRadius50 {
  border-radius: 50%;
}

.margin-top6 {
  margin-top: 6px;
}
.padding20 {
  padding: 20px;
}
.padding16 {
  padding: 16px;
}
.lightBorder {
  border: 1px solid #eeeeee !important;
}
.light2Border {
  border: 1px solid #e2e2e2;
}
.whiteButtonBorder {
  border: 1px solid #0e5b48 !important;
}
.opacity50 {
  opacity: 0.5;
}
.font-size18 {
  font-size: 18px;
}
.font-size20 {
  font-size: 20px;
}
.font-size16 {
  font-size: 16px;
}
.font-size17 {
  font-size: 17px;
}
.font-size14 {
  font-size: 14px;
}
.font-size12 {
  font-size: 12px;
}
.font-size10 {
  font-size: 10px;
}
.font-size21 {
  font-size: 21px;
}
.font-size8 {
  font-size: 8px;
}
.font-size9 {
  font-size: 9px;
}
.font-size22 {
  font-size: 22px;
}

.line-height26 {
  line-height: 26px;
}
.boxShadow1 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.line-height24 {
  line-height: 24px;
}
.boxShadow8 {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}
.boxShadow15 {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.stdFontStyle {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
}
.letterSpacing015 {
  letter-spacing: 0.0015rem;
}
.letterSpacing05 {
  letter-spacing: 0.005rem;
}
.letterSpacing025 {
  letter-spacing: 0.0025rem;
}
.letterSpacing0125 {
  letter-spacing: 0.0125em;
}
.letterSpacing001 {
  letter-spacing: 0.001em;
}
.letterSpacing004 {
  letter-spacing: 0.004em;
}
.letterSpacing02 {
  letter-spacing: 0.2px;
}
.font-weight400 {
  font-weight: 400;
}
.font-weight500 {
  font-weight: 500;
}
.font-weight600 {
  font-weight: 600;
}
.font-weight700 {
  font-weight: 700;
}
.line-height23 {
  line-height: 23px;
}
.line-height28 {
  line-height: 28px;
}
.line-height21 {
  line-height: 21px;
}
.line-height20 {
  line-height: 20px;
}
.line-height19 {
  line-height: 19px;
}
.line-height16 {
  line-height: 16px;
}
.line-height13 {
  line-height: 13px;
}
.line-height14 {
  line-height: 14px;
}
.line-height12 {
  line-height: 12px;
}
.line-height10 {
  line-height: 10px;
}
.line-height6 {
  line-height: 6px;
}
.margin-left16 {
  margin-left: 16px;
}
.margin-left20 {
  margin-left: 20px;
}

.margin-left12 {
  margin-left: 12px;
}
.margin-right12 {
  margin-right: 12px;
}

.margin-right16 {
  margin-right: 16px;
}
.margin-right20 {
  margin-right: 20px;
}

.margin-left24 {
  margin-left: 24px;
}
.margin-top24 {
  margin-top: 24px;
}
.margin-left6 {
  margin-left: 6px;
}
.margin-bottom14 {
  margin-bottom: 14px;
}
.margin-top16 {
  margin-top: 16px;
}
.margin-top18 {
  margin-top: 18px;
}
.margin-top20 {
  margin-top: 20px;
}
.margin-top30 {
  margin-top: 30px;
}
.margin-right20 {
  margin-right: 20px;
}
.margin-right26 {
  margin-right: 26px;
}
.margin-bottom16 {
  margin-bottom: 16px;
}
.margin-bottom18 {
  margin-bottom: 18px;
}
.margin-bottom20 {
  margin-bottom: 20px;
}
.margin-bottom40 {
  margin-bottom: 40px;
}
.margin-bottom4 {
  margin-bottom: 4px;
}
.margin-top40 {
  margin-top: 40px;
}
.margin-bottom24 {
  margin-bottom: 24px;
}
.margin-right24 {
  margin-right: 24px;
}
.margin-top12 {
  margin-top: 12px;
}
.margin-top10 {
  margin-top: 10px;
}
.margin-top5 {
  margin-top: 5px;
}
.padding-top10 {
  padding-top: 10px;
}
.padding-bottom10 {
  padding-bottom: 10px;
}

.padding-bottom6 {
  padding-bottom: 6px;
}
.padding-top6 {
  padding-top: 6px;
}
.padding24 {
  padding: 24px;
}
.margin-top14 {
  margin-top: 14px;
}
.margin-top24 {
  margin-top: 24px;
}
.margin-y-auto {
  margin: 0 unset;
}
.padding-left6 {
  padding-left: 6px;
}
.padding-left10 {
  padding-left: 10px;
}
.padding-right10 {
  padding-right: 10px;
}
.padding-left20 {
  padding-left: 20px;
}
.padding-left24 {
  padding-left: 24px;
}
.padding-right24 {
  padding-right: 24px;
}
.padding-left16 {
  padding-left: 16px;
}
.padding-right16 {
  padding-right: 16px;
}
.padding-right20 {
  padding-right: 20px;
}
.padding-top16 {
  padding-top: 16px;
}
.padding-bottom16 {
  padding-bottom: 16px;
}
.padding-top20 {
  padding-top: 20px;
}
.padding-top24 {
  padding-top: 24px;
}
.top24 {
  top: 24px;
}
.py-5px {
  padding-top: 5px;
  padding-bottom: 5px;
}
.padding-left9_5 {
  padding-left: 9.5px;
}
.padding-top9_5 {
  padding-left: 9.5px;
}
.padding-top11_5 {
  padding-left: 11.5px;
}
.padding-right9_5 {
  padding-right: 9.5px;
}
.padding-right13 {
  padding-right: 13px;
}
.padding-left13 {
  padding-left: 13px;
}

.padding-left30 {
  padding-left: 30px !important;
}
.padding-right30 {
  padding-right: 30px;
}

.padding-left32 {
  padding-left: 32px;
}
.padding-right32 {
  padding-right: 32px;
}
.padding-left-right16 {
  padding-left: 16px;
  padding-right: 16px;
}
.textColorWhite {
  color: White;
}
.textColorBlack {
  color: black;
}
.borderRadiusTop {
  border-radius: 8px 8px 0px 0px;
}
.borderRadiusBottom {
  border-radius: 0px 0px 8px 8px;
}

.borderRadiusBottom12 {
  border-radius: 0px 0px 12px 12px;
}

.padding16 {
  padding: 16px;
}
.padding1 {
  padding: 1px;
}
.padding-top1 {
  padding-top: 1px;
}
.margin16 {
  margin: 16px;
}
.positionRelative {
  position: relative;
}
.positionAbsolute {
  position: absolute;
}
.display-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-auto {
  margin: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.mlr-auto {
  margin-left: auto;
  margin-right: auto;
}
.mtb-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.colorLightGrey {
  color: #e2e2e2;
}
.backLightGrey {
  background: #e2e2e2;
}
.colorLightGrey5 {
  color: #f5f5f5;
}
.backLightGrey5 {
  background: #f5f5f5;
}

.padding-top8 {
  padding-top: 8px;
}
.padding-bottom8 {
  padding-bottom: 8px;
}
.padding-left8 {
  padding-left: 8px;
}
.padding-right8 {
  padding-right: 8px;
}
.padding8 {
  padding: 8px;
}
.margin-top4 {
  margin-top: 4px;
}

.margin-left4 {
  margin-left: 4px;
}
.margin-right4 {
  margin-right: 4px;
}
.margin-top8 {
  margin-top: 8px;
}
.margin-bottom8 {
  margin-bottom: 8px;
}
.margin-bottom0 {
  margin-bottom: 0px;
}
.margin-left8 {
  margin-left: 8px;
}
.margin-right8 {
  margin-right: 8px;
}
.margin8 {
  margin: 8px;
}
.padding-top12 {
  padding-top: 12px;
}
.padding-bottom12 {
  padding-bottom: 12px;
}
.padding-left12 {
  padding-left: 12px;
}
.padding-right12 {
  padding-right: 12px;
}
.padding-bottom14 {
  padding-bottom: 14px;
}
.padding-top14 {
  padding-top: 14px;
}
.padding-right14 {
  padding-right: 14px;
}
.padding12 {
  padding: 12px;
}
.backgroundWhite {
  background: white;
}
.cursorPointer {
  cursor: pointer;
}
.colorDark14 {
  color: #222b45 !important;
}
.colorDark15 {
  color: #8E8E8E !important;
}
.colorDark7 {
  color: #4d5557;
}
.colorDark6 {
  color: #363b3d;
}
.colorDark8 {
  color: #656e71;
}
.colorDark9 {
  color: #7c878b;
}
.colorDark1 {
  color: #111313;
}
.colorDark2 {
  color: #080c0c;
}
.colorDark13 {
  color: #959393;
}
.colorAccent5 {
  color: #179778;
}
.colorAccent4 {
  color: #127960;
}
.colorAccent3 {
  color: #0e5b48;
}
.colorGreen1 {
  color: #5a9a07;
}

.colorRed4 {
  color: #d92e30;
}

.colorAccent9 {
  color: #dfece9;
}
.backAccent3 {
  background-color: #0e5b48;
}
.backAccent4 {
  background-color: #127960;
}
.backAccent9 {
  background-color: #dfece9;
}

.backgroundLightBrown {
  background: #f8f8f8;
}
.overWriteInputButtonCSS {
  border-width: 0px;
  outline: none;
}
.overWriteInputCSS {
  outline: none;
}
.width-100 {
  width: 100%;
}
.maxWidth33 {
  max-width: 33.3%;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.textCenter {
  text-align: center;
}
.text-decoration-none {
  text-decoration: none;
}
.grayDark {
  color: #666666;
}
.textWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.white-space-noWrap {
  white-space: nowrap;
}
.scrollHorizontal {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scrollHorizontal::-webkit-scrollbar {
  display: none;
}
.flex50 {
  flex: 50%;
}
.flex100 {
  flex: 100%;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-d-col {
  flex-direction: column;
}
.linear-gradient1 {
  background: linear-gradient(294.04deg, #e1e3e3 1.44%, #dfece9 93.06%);
}
.linear-gradient2 {
  background: linear-gradient(293.5deg, #293a3a 47.87%, #179778 123.65%);
}

.padding-bottom5 {
  padding-bottom: 5px;
}

.padding-bottom4 {
  padding-bottom: 4px;
}

.padding-bottom2 {
  padding-bottom: 2px;
}

.padding-top4 {
  padding-top: 4px;
}
.padding-top5 {
  padding-top: 5px;
}

.padding-top2 {
  padding-top: 2px;
}

.padding-left4 {
  padding-left: 4px;
}
.padding-right4 {
  padding-right: 4px;
}
.padding4 {
  padding: 4px;
}
.colorAccent2 {
  color: #093c30;
}

.colorAlert1 {
  color: #744e07;
}

.scroll-horizontal {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-horizontal::-webkit-scrollbar {
  display: none;
}

.backgroundDarkGrey {
  background: #293a3a;
}
.backgroundColorF5 {
  background: #f5f5f5;
}
.padding-left21 {
  padding-left: 21px;
}
.padding-right19 {
  padding-right: 19px;
}

.padding-bottom20 {
  padding-bottom: 20px;
}

.padding-bottom24 {
  padding-bottom: 24px;
}

.text-transparent {
  color: transparent;
}

.m0 {
  margin: 0;
}
.p0 {
  padding: 0;
}
.padding-top-unset {
  padding-top: unset !important;
}
.first-letter-caps {
  text-transform: capitalize;
}

.line-height18 {
  line-height: 18px;
}

.gap2 {
  gap: 2px;
}

.gap4 {
  gap: 4px;
}

.gap5 {
  gap: 5px;
}

.gap8 {
  gap: 8px;
}
.gap6 {
  gap: 6px;
}
.gap12 {
  gap: 12px;
}
.gap14 {
  gap: 14px;
}
.gap16 {
  gap: 16px;
}
.gap17 {
  gap: 17px;
}
.gap19 {
  gap: 19px;
}
.gap20 {
  gap: 20px;
}
.gap30 {
  gap: 30px;
}
.gap10 {
  gap: 10px;
}
.gap32 {
  gap: 32px;
}
.gap60 {
  gap: 60px;
}
.justify-content-end {
  justify-content: end;
}
.justify-content-start {
  justify-content: end;
}
.justify-content-left {
  justify-content: left;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-space-around {
  justify-content: space-around;
}

.colorSecondary8 {
  color: #b8bdbd;
}
.va-abaseline {
  vertical-align: baseline;
}
.asterisk {
  color: #d5261b;
  margin-top: -2px;
}
.col4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.flex1 {
  flex: 1;
}
.OverThePageCSS {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 999;
  width: 100%;
}

.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}

.col7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.header-height-61 {
  height: 61px;
}
.d-inline-grid {
  display: inline-grid;
  display: -ms-inline-grid;
  display: -moz-inline-grid;
}

.gap2 {
  gap: 2px;
}

.colorDark5 {
  color: #1f2223;
}
.colorBlue1 {
  color: #196599;
}

.colorDark4 {
  color: #1c1e1f;
}
.display-block {
  display: block;
}
.textColorBlue {
  color: #2081c3;
}
.textColorSuccess {
  color: #1d7330;
}
.textColorError {
  color: #d42015;
}
.backColorAccent5 {
  background-color: #e1e3e3;
}
.border-radius50 {
  border-radius: 50%;
}
.colorDark12 {
  color: #666760;
}
.colorDarkBlue1 {
  color: #2c3e50;
}
.width-50 {
  width: 50%;
}
.width-40 {
  width: 40%;
}
.width-30 {
  width: 30%;
}

.width-70 {
  width: 70%;
}

.width-75 {
  width: 75%;
}
.colorBlack700 {
  color: #121212;
}
.colorBlack333 {
  color: #333333;
}
.colorGrey4848 {
  color: #484848;
}
.colorBlack212 {
  color: #212e2e;
}
.colorGreen269 {
  color: #269940;
}
.flex-column {
  flex-direction: column;
}
.backAlertWarning4 {
  background-color: #e89c0d;
}
.alertWarning4 {
  color: #e89c0d;
}
.alertWarning5 {
  color: #ae750a;
}
.height-40p {
  height: 40px;
}
.colorDark3 {
  color: #181a1b;
}

.colorDark10 {
  color: #1c1e1f;
}

.colorDark11 {
  color: #d42015;
}
.colorDark-background-color-red {
  background-color: #d42015;
}
.dont-show-empty-div:empty {
  display: none;
}

.input-style-error {
  border: 1px solid #d42015 !important;
}
.input-error-style {
  padding-top: 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.004em;
  color: #d42015;
}
.input-style-disable {
  background: #eeeeee !important;
}
.gap24 {
  gap: 24px;
}

.colorSecondary {
  color: #b3b3b3;
}
.margin-bottom12 {
  margin-bottom: 12px;
}

/* .less-data-pagination {
  position: absolute;
  bottom: 58px;
  left: 50%;
  transform: translate(-50%, 0);
} */
.margin-bottom80 {
  margin-bottom: 80px;
}
.required::after {
  content: "*";
  color: #d42015;
}
.colorSecondary5 {
  color: #293a3a;
}
.colorSecondary3 {
  color: #192323;
}
.colorSecondary6 {
  color: #677373;
}
.gap24 {
  gap: 24px;
}

.pointer-events-none {
  pointer-events: none;
}

.placeholderColorb8bdbd::placeholder {
  color: #b8bdbd;
  opacity: 1; /* Firefox */
}

.placeholderColorb8bdbd:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b8bdbd;
}

.placeholderColorb8bdbd::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b8bdbd;
}

.active-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  /* Accent/5 */

  background: #179778;
}
.toast-bottom-right .toast-error {
  background-color: #bd362f !important;
}
.toast-bottom-right .toast-info {
  background-color: #2f96b4 !important;
}
.toast-bottom-right .toast-success {
  background-color: #51a351 !important;
}
.toast-bottom-right .toast-warning {
  background-color: #f89406;
}

.scroll-vertical {
  overflow-y: auto;
  overflow-x: hidden;
}
.z-index3 {
  z-index: 3 !important;
}
.z-index5 {
  z-index: 5 !important;
}
.line-height30 {
  line-height: 30px;
}

.line-height8 {
  line-height: 8px;
}
.font-size24 {
  font-size: 24px;
}
.screen-disable {
  pointer-events: none;
  opacity: 0.5;
}
.opacity-50 {
  opacity: 0.5;
}
.display-inline {
  display: inline;
}
.btn-disable-grey {
  background-color: #b8bdbd;
}

.text-underline {
  text-decoration-line: underline;
}
.margin-bottom10 {
  margin-bottom: 10px;
}
.align-items-flex-start {
  align-items: flex-start;
}

.break-word {
  word-break: break-word;
}

.hr-divider {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.d-hr-divider {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #e1e3e3;
}
.d-hr-dividerd9d9 {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #d9d9d9;
}
.width-fit-content {
  width: fit-content;
}

.margin-top2 {
  margin-top: 2px;
}

@media screen and (max-width: 1023px) {
  body {
    padding-bottom: 56px;
  }
}

.height40px {
  height: 40px;
}

.cta-btn {
  background: #0e5b48;
  border-radius: 6px;
  padding: 12px 0;
  width: 100%;
  font-weight: 500;
  color: #fff;
}
.cta-btn:disabled {
  background: #b8bdbd;
}

.button-disabled {
  background: #b8bdbd !important;
}

.indicator .carousel-indicators {
  display: flex !important;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.indicator .carousel-indicators > li {
  width: 6px;
  height: 4px;
  border-radius: 20%/50%;
  background-color: #a9b0b0;
}

.indicator .carousel-indicators > li.active {
  background-color: #293a3a;
  width: 24px;
  height: 4px;
  border-radius: 8%/50%;
}
.padding-top1 {
  padding-top: 1px;
}

/* Desktop styles */
.d-width-1-col {
  width: auto;
}
.d-width-2-col {
  width: auto;
}

d-profile-options {
  width: auto;
}
/* use these media queries strictly for layout widths and margins if needed */
@media only screen and (min-width: 1024px) {
  .d-width-1-col {
    width: 80vw;
  }
  .d-width-2-col {
    width: 90vw;
  }
  .d-profile-options {
    width: 55vw;
  }
  .input-error-style {
    padding-top: 4px;
  }
  .content-min-height {
    min-height: 450px;
  }
}

@media only screen and (min-width: 1200px) {
  .d-width-1-col {
    width: 70vw;
  }
  .d-width-2-col {
    width: 90vw;
  }
  .d-profile-options {
    width: 50vw;
  }
}

@media only screen and (min-width: 1400px) {
  .d-width-1-col {
    width: 57vw;
  }
  .d-width-2-col {
    width: 86vw;
  }
  .d-profile-options {
    width: 42vw;
  }
}

@media only screen and (min-width: 1900px) {
  .d-width-1-col {
    width: 50vw;
  }
  .d-width-2-col {
    width: 70vw;
  }
  .d-profile-options {
    width: 33vw;
  }
}
.padding-left-right100 {
  padding-left: 100px;
  padding-right: 100px;
}
.padding-left-right30 {
  padding-left: 30px;
  padding-right: 30px;
}

.gap18 {
  gap: 18px;
}
.gap40 {
  gap: 40px;
}
.gap60 {
  gap: 60px;
}
.margin-bottom4 {
  margin-bottom: 4px;
}

div.empty-d-none:empty {
  display: none !important;
}
.left-right-margin-100 {
  margin-left: 100px;
  margin-right: 100px;
}
.top-bottom-margin-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.flex33 {
  flex: 33%;
}

.max-w33 {
  max-width: 33%;
}

.flex66 {
  flex: 66;
}
.max-w66 {
  max-width: 66%;
}

.flex34 {
  flex: 34;
}
.max-w34 {
  max-width: 34%;
}

.reset-a,
.reset-a:hover,
.reset-a:visited,
.reset-a:focus,
.reset-a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
  cursor: auto;
}

.cursor-pointer {
  cursor: pointer !important;
}

.margin-top21 {
  margin-top: 21px;
}

.va-top {
  vertical-align: top;
}
.top-40 {
  top: 40px !important;
}

.top-0 {
  top: 0px !important;
}
.position-sticky {
  position: sticky;
}
.padding105 {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}
.HeaderFixedCSS {
  padding-bottom: 60px;
}
.scroll-smooth-behaviour {
  scroll-behavior: smooth !important;
}
a {
  color: unset !important;
  text-decoration: none !important;
  background-color: transparent;
}
.desk_card_border {
  border: 1px solid #e1e3e3 !important;
}
.animated-element {
  /* flex: 0 0 calc(33.33% - 20px); */
  opacity: 0;
  transform: translateY(20px); /* Adjust this to the desired animation effect */

  transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-element.animate {
  opacity: 1;
  transform: translateY(0);
}

.common-desktop-fontstyle {
  font-size: 16px !important;
  line-height: 19px !important;
  letter-spacing: 0.005rem !important;
}

.mw-100 {
  max-width: 100% !important;
}
.auctionPosition .react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 3 !important;
}

.flexGrow2 {
  flex-grow: 2;
}
.flexGrow1 {
  flex-grow: 1;
}
